import '../css/app.css';
import React from 'react';
import {render} from "react-dom";
import {Provider} from "react-redux";
import configureStore from "./redux/store";
import App from "./containers/app";
import {APP_VERSION, CDN_PATH, IS_DEV, IS_LOGGED_IN, IS_PROD, IS_STAGING, PLAYER_ID, SEND_PLAYER_ID_AS_CUSTOM_DIMENSION} from "Setup/config";
import ErrorBoundary from "Components/ErrorBoundary";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import history from "Setup/history";
import {initialiseMicrosoftTracking} from "Helpers/microsoft";
import {initialiseSnapchatTracking} from "Helpers/snapchat";

if (IS_DEV) {
    __webpack_public_path__ = `${window.__webpack_public_path__}/`;
} else {
    __webpack_public_path__ = `${CDN_PATH}/`;
}


const elementExists = (domId) => {
    return document.getElementById(domId);
};

if (elementExists("app")) {
    const store = configureStore();

    initialiseSnapchatTracking();
    initialiseMicrosoftTracking();

    if(window){
        window.dataLayer = window.dataLayer || [];
    }

    let initialScope = {};
    if (IS_LOGGED_IN) {
        initialScope.user = {};
        initialScope.user.id = window.MORPHEUS.core.playerId;
        initialScope.user.name = window.MORPHEUS.core.username;
    }

    let sentryIntegrations = [
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        })];

    Sentry.init({
        release: APP_VERSION,
        debug: false,
        dsn: "https://dec5a4bcd373480d81ebaa478990b04f@o215877.ingest.sentry.io/5833912",
        integrations: sentryIntegrations,
        environment: IS_DEV ? 'local' : IS_STAGING ? 'staging' : 'production',
        initialScope,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: IS_PROD ? 0.1 : 1.0,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        // We recommend adjusting this value in production, or using tracesSampler for finer control
        tracesSampleRate: IS_PROD ? 0.1 : 1.0,
        blacklistUrls: ["trustpilot.com", "zdassets.com"],
        ignoreErrors: [
            // Error generated by a bug in auto-fill library from browser
            // https://github.com/getsentry/sentry/issues/5267
            /Blocked a frame with origin/,
            // Error generated by video elements - reason unknown
            /ResizeObserver loop limit exceeded/,
            /ResizeObserver loop completed with undelivered notifications./
        ]
    });

    render(
        <Provider store={store}>
            <ErrorBoundary>
                <App/>
            </ErrorBoundary>
        </Provider>,
        document.getElementById("app")
    );
}

if (module.hot) {
    module.hot.accept();
}
