import {FormControl, FormControlLabel, makeStyles, Paper, Radio, RadioGroup} from "@material-ui/core";
import React, {useState} from 'react';
import clsx from "clsx";
import classNames from 'classnames';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import {BP_LARGE} from "Theme/breakpoints";
import {useGomTheme} from "Hooks/ui/useGomTheme";

const useThemeSwitchStyles = makeStyles(theme => ({
    root: {
        overflow: "hidden",
        bottom: "-1px",
        position: "fixed",
        left: theme.spacing(1),
        zIndex: 10000,
        paddingTop: 10,
        transition: 'all 0.1s ease',
        '&.theme-switcher__show label:first-child': {
            display: 'none'
        },
        '&.theme-switcher__hide': {
            bottom: '-335px'
        },
        [theme.breakpoints.down(BP_LARGE)]: {
            zIndex: 1305,
            '&.theme-switcher__hide': {
                bottom: '-345px'
            },
        }
    },
    paper: {
        borderRadius: `5px 5px 0 0`,
    },
    formlabel: {
        color: "black",
        '&.Mui-focused': {
            color: "black"
        },
    },
    fieldset: {
        margin: theme.spacing(1),
        fontSize: 9
    },
    icon: {
        position: 'absolute',
        top: 8,
        right: 1,
        cursor: 'pointer',
        zIndex: 10,
    },
}));

const ThemeSwitcher = () => {

    const {setNewTheme, themeCode} = useGomTheme();
    const classes = useThemeSwitchStyles();
    const [isVisible, setVisible] = useState(false);

    const baseClass = `theme-switcher`;
    const classGroup = classNames(
        baseClass,
        [{
            [`${baseClass}__show`]: isVisible,
            [`${baseClass}__hide`]: !isVisible
        }]
    );


    const toggleVisible = () => {
        setVisible(!isVisible)
    };

    const ToggleIcon = (props) => {
        if (!isVisible) {
            return <ToggleOffIcon {...props} color="disabled"/>;
        }

        return <ToggleOnIcon {...props} />;
    };

    const themes = {
        bd: "Bingo Diamond",
        bb: "Butlers Bingo",
        cf: "Casino Fantastico",
        cd: "Casino of Dreams",
        db: "Dotty Bingo",
        gb: "Glossy Bingo",
        l247: "Lucky 247",
        rb: "Rosy Bingo"
    };

    return (
        <div className={clsx(classes.root, classGroup)}>
            <Paper square className={classes.paper}>
                <ToggleIcon color={"primary"} className={classes.icon} onClick={toggleVisible}/>
                <FormControl component="fieldset" className={classes.fieldset}>
                    <RadioGroup aria-label="theme" name="theme" color={"primary"} value={themeCode} onChange={e => setNewTheme(e.target.value)}>
                        <FormControlLabel value={themeCode} control={<Radio color="primary"/>} label={themes[themeCode]}/>

                        {Object.keys(themes).map(t => (
                            <FormControlLabel key={`theme-${t}`} value={t} control={<Radio color="primary"/>} label={themes[t]}/>
                        ))}
                    </RadioGroup>
                </FormControl>
            </Paper>
        </div>
    );
};

export default ThemeSwitcher;
