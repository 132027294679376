import React from 'react';
import axios from "axios";
import {GenericPageContainer} from "./GenericPage";
import {sendLogglyEvent} from "Helpers/loggly";
import {BRAND_NAME, IS_DEV} from "Setup/config";
import {Box, CircularProgress, Typography} from "@material-ui/core";
// import {sleep} from "Helpers/async";
import {PrimaryButton} from "Components/Button";

function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

const ColumnHeader = props => (
    <Typography style={{textDecoration: "underline", fontWeight: "bold"}} paragraph>{props.children}</Typography>
)

export default function DragonfishOperatedSites() {
    const [hasError, setHasError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [sitesData, setSitesData] = React.useState();

    const getDragonfishSitesData = async () => {
        setIsLoading(true);
        setHasError(false);

        try {
            const res = await axios.post('https://lgs.bingosys.net/Services/Common/CommonService.svc/GetSkinListConfiguration', {
                NetworkIdList: [1, 2, 5, 6, 8, 9, 11, 13, 14, 16, 17, 18, 21, 22, 24, 26, 27, 29, 31]
            })

            if (res.data.ResponseMessage === "OK") {
                if (res?.data?.SkinsConfig && Array.isArray(res?.data.SkinsConfig)) {
                    const {SkinsConfig} = res.data;
                    const skinNames = SkinsConfig.filter(s => s.Status === 1).map(s => s.SkinURL.toLowerCase()).sort();

                    const COLS = 3
                    const splitListColumns = sliceIntoChunks(skinNames, Math.ceil(skinNames.length / COLS));

                    setSitesData(splitListColumns)
                    setIsLoading(false);
                } else {
                    setHasError(true);
                    setIsLoading(false);
                }
            }
        } catch (e) {
            setHasError(true);
            setIsLoading(false);

            sendLogglyEvent('Generic', 'Error loading DF skins data', {error: e.message})
        }
    };

    React.useEffect(() => {
        if (!IS_DEV) {
            getDragonfishSitesData();
        }
    }, [])

    const BROADWAY_SITES = [
        {name: "Butlers Bingo", url: "www.butlersbingo.com"},
        {name: "Dotty Bingo", url: "www.dottybingo.com"},
        {name: "Glossy Bingo", url: "www.glossybingo.com"},
        {name: "Rosy Bingo", url: "www.rosybingo.com"},
        {name: "Bingo Diamond", url: "www.bingodiamond.com"},
        {name: "Casino of Dreams", url: "www.casinoofdreams.uk"},
        {name: "Casino Fantastico", url: "www.casinofantastico.co.uk"},
        {name: "Lucky 247", url: "www.lucky247.uk"},
    ].filter(s => s.name.toLowerCase() !== BRAND_NAME.toLowerCase()).map(s => s.url).sort();

    return (
        <GenericPageContainer title="Sites Operated Under All Licences in the Broadway Gaming Group">
            {
                !IS_DEV && isLoading ?
                    <Box py={6} display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress/>
                    </Box> :
                    !IS_DEV && hasError &&
                    <Box textAlign="center">
                        <Typography paragraph>Oops, looks like something went wrong. Please try again later.</Typography>
                        <PrimaryButton onClick={getDragonfishSitesData}>Retry</PrimaryButton>
                    </Box>
            }

            {IS_DEV ?
                <Typography style={{fontStyle: 'italic', textAlign: 'center'}}>Endpoint is not whitelisted for development environment. Please try staging or
                    production.</Typography> :
                sitesData &&
                <>
                    <Box mb={2}>
                        <ColumnHeader>Sites Operated under this Licence</ColumnHeader>
                        {BROADWAY_SITES.map((v, k) => <Typography key={`bede-${k}`}>{v}</Typography>)}
                    </Box>

                    <Box>
                        <ColumnHeader>Other Sites Operated under the Broadway Gaming Group</ColumnHeader>
                        <Box className="row">
                            {sitesData.map(col => (
                                <Box className="small-12 medium-6 large-4 column">
                                    {col.map((v, k) => <Typography key={`site-${k}`}>{v}</Typography>)}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </>
            }
        </GenericPageContainer>
    );
}
