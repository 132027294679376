import {UPDATE_BINGO_FEED} from "Redux/actions/bingo";

let initialState = {isLoaded: false, rooms: [], specials: []};

const bingoReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_BINGO_FEED:
            return {
                ...state,
                isLoaded: true,
                rooms: action.newRooms,
                specials: action.newSpecials,
                dictionary: action.roomDictionary
            };
        default:
            return {
                ...state
            };
    }
};

export default bingoReducer;
